<template>
  <footer class="form-foot xa-cell">
    <el-button type="primary" size="small" @click="$emit('submit')"
      >确定</el-button
    >
    <el-button plain size="small" @click="onCancel">取消</el-button>
  </footer>
</template>
<script>
export default {
  methods: {
    onCancel() {
      this.gotoList()
    },
    gotoList() {
      this.$router.replace({
        path: '/home/voucher/datatable',
        query: {
          src: '/main/operation/coupon/index_config',
          params: '[]',
          title: '优惠券列表',
          nav: 1
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-foot {
  padding: 0 15px;
  height: 40px;
  background-color: #fff;
  flex-shrink: 0;
  box-shadow: 0px 3px 6px 0px #000;
  justify-content: center;
}
</style>
